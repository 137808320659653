@import "variable";
@import "co-manage-popup";
@import "boost-survey-popup";

// used for events and pods

.main-nav-wrap {
  position: sticky;
  top: 0;
  z-index: 10;
  background: $bg-color;
  margin: 0 auto;
  border-bottom: 1px solid $grey-light;

  &.nav-sticky {
    position: fixed;
    top: 0px;
    width: 100%;
  }
}

.main-nav {
  @extend %clearfix;
  position: relative;
  min-height: 75px;
  margin: 0  auto;
  min-width: 300px;
  max-width: 950px;

  @media (max-width: $mobile_web_breakpoint) {
    width: 100%;
    height: 50px;
    min-height: 50px;

    .link-actions {
      display: none;
    }
  }
}

.main-nav-wrap.nav-sticky .main-nav {
  left: 0 ! important;
}

// manage event tools
.nav-list {
  @extend %clearfix;

  .nav-item {
    position: relative;
    float: left;
    border-right: 1px solid $grey-light;
    @media (max-width: 500px) {
      &:last-child {
        border-right: none;
      }
    }
  }

  .nav-item_main {
    float: left;
    .list-pop_content {
      top: 4.5em;
      left: 1em;

      &.nav-item_main__list-pop {
        max-width: 500px;
      }

      @media (max-width: 450px) {
        top: 3.5em;
        left: inherit;
        &.nav-item_main__list-pop {
          max-width: 250px;
        }
        &.nav-item_main__list-pop__right {
          right: 0;
          &:before {
            right: 15px;
            left: inherit;
          }
        }
      }
    }
  }

  .nav-icon {
    float: left;
    width: 68px;
    height: 75px;
    padding: 1.5em 0 0;
    color: $grey-medium;
    font-size: $font-S;
    text-align: center;
    box-sizing: border-box;

    &:before {
      display: block;
      height: 28px;
      line-height: 28px;
      font-size: 28px;
      color: $link_color;
    }

    &:hover:before {
      color: $black;
    }

    @media (max-width: $mobile_web_breakpoint) {
      display: none
    }
  }

  .nav-icon__guide {
    &:before {
      content: '?';
      @include square(25px);
      margin: 2px auto 1px auto;
      background: $grey-medium;
      border-radius: 50%;
      font-size: 16px;
      line-height: 25px;
      color: $grey-light2;
    }
  }

  .list-pop_content {
    top: 3.5em;
    text-align: left;
    > li > a {
      @extend %ellipsis-text;
    }
  }
}

.nav-list_sub {
  float: left;
  height: 75px;
  box-sizing: border-box;
  padding-right: 1em;

  &.icon-arrow-right:before {
    display: inline-block;
    margin-top: 2.4em;
    color: $grey-medium-light;
    font-size: $font-S;
  }

  > li {
    display: inline-block;
    vertical-align: top;

    > a {
        padding: 1.5em 1em;
        display: block;
        height: 75px;
        box-sizing: border-box;

        &.selected {
          color: $black;
          border-bottom: 3px solid $black;
        }
      }

    .addition {
      display: block;
      color: $grey-medium-light;
      font-size: .8em;  
    }
  }

  .nav-list_sub_more {
    display: inline-block;
    vertical-align: middle;

    .list-pop_trigger {
      font-weight: bold;
      font-size: $font_M;
    }

    .list-pop_content {
      right: 0;
      &:before {
        right: 5px;
        left: inherit;
      }
    }
  }

  @media (max-width: 680px) {
    &.icon-arrow-right:before {
      margin-top: 1.5em;
    }
    > li {
      > a {
        padding: 1.5em .5em;
      }
      .addition { display: none; }
    }
  }

  @media (max-width: $mobile_web_breakpoint) { 
    &.icon-arrow-right:before {
      display: none;
    }

    padding-right: .5em;
    padding-left: .5em;
    height: 50px;

    > li {
      font-size: $font_S;
      > a {
        padding: 1em .4em;
        height: 50px;
      }
    }
  }
}

.nav-list_sub__pod {
  @media (max-width: 500px) {
    display: none;
  }
}

.nav-item_link {
  float: left;
}

// Link Actions
.main-nav {
  .link-actions {
    position: absolute;
    top: 0;
    right: 0;

    // More links
    .list-pop_content {
      width: 11em;
      top: 70px;
      right: 0;
      text-align: left;

      &:before {
        right: 10px;
        left: auto;
      }
    }  
  }
}

.link-actions_link {
  float: right;
  box-sizing: border-box;
  height: 75px;
  margin-left: 2em;
  padding-top: 1.5em;
  text-align: center;
  font-size: $font-S;
  color: $grey-medium;

  .link-actions_link_icon {
    display: inline-block;
    width: 26px;
    height: 26px;
    margin: 0 auto;
    font-weight: bold;
    font-size: $font-L;
    color: $link_color;
    vertical-align: top;

    .fill-color {
      fill: $link_color;
    }
  }

  &:hover {
    color: $black;
    .fill-color {
      fill: $black;
    }
    .link-actions_link_icon {
      color: $black;
    }
  }
}

.nav-list_sub .link-actions {
  position: initial;
}

.edit-action-buttons {
  position: absolute;
  top: 1.3em;
  right: 0;
  @media (min-width: $mobile_web_breakpoint) {
    margin-right: 1em;
  }

  @media (max-width: $mobile_web_breakpoint) {
    position: static;
    padding: 1.3em 0;
    text-align: center;
    background-color: $grey-medium;
    border-top: 1px solid $grey-light;

    .button { min-width: 8em; }
  }
}

.svg-add-co-manager:hover {
  #svg-add-co-manager-circle {
    stroke: $black;
  }
}

.svg-circle-plus-fine:hover {
  #shape {
    fill: $black;
  }
  #oval {
    stroke: $black;
  }
}
