.cover-button-wrap {
  position: absolute !important;
  bottom: 1em;
  right: 1em;

  .button-overlay {
    border: 1px solid $white;
    min-width: auto;
    padding-right: 1em;
    padding-left: 1em;
  }
}

.cover-button_replace {
  .add-cover-img {
    width: 30px;
  }

  .caption {
    padding-left: .5em;
  }

  .add-cover-img, .caption {
    vertical-align: middle;
    cursor: pointer;
  }

  .list-pop_content {
    text-align: left;
    top: 4em;
    width: 150px;
    right: 0;
    &:before {
      right: 10px;
      left: initial;
    }
  }

  @media (max-width: 500px) {
    .caption {
      display: none;
    }
  }
}

.button-loading {
  .loading-icon {
    width: 1em;
    vertical-align: middle;
  }
}

.cropper-view-box {
  outline: none;
}