.skeleton {
  opacity: .7;
  animation: skeleton-loading 1s linear infinite alternate;
  background-color: $grey-light;
}

@keyframes skeleton-loading {
  0% {
    background-color: $grey-light;
  }

  100% {
    background-color: lighten($grey-light, 10%);
  }
}