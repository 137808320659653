@import "susy";


// Mobile friendly centered layout
.inner {
	@include container (600px);
	position: relative;
}

// currently on checkout, login screen, create event, attendee ticket view, settings page, check-in_settings in combination with meta viewport tag

// Layout 
#wrap { min-width: 300px;}

$base: (
	columns: 6,
	gutters: 1/4,
	gutter-position: inside,
);

#content-main,
#container,
nav.global-nav {
	@include container (970px);
	min-width: 300px;
	width: auto;
}


#content-main {
	padding: 2em 1em 1em;
	box-sizing: border-box;
}

// Main Column on the left, Sub Column on the right
.col-main { 
	@include span (4 of 6);
}

.col-sub {
	@include span (2 at 5 of 6);
}

// Main Column on the right, Sub Column on left
.col-main-right  { 
	@include span (4 at 3 of 6);

	&.wide  { 
		@include span (5 at 2 of 6);
	}
}

.col-sub-left { 
	@include span (2 of 6);

	&.narrow { 
		@include span (1 of 6);
	}
}


@media only screen and (max-width: 700px) {
	.col-main, .col-main-right, .col-main-right.wide,
	.col-sub, .col-sub-left, .col-sub-left.narrow {
		@include span (full);
		float: none;
	}
}