.checkbox-custom,
.radio-custom {
  opacity: 0;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  cursor: pointer;
}

.checkbox-custom + .checkbox-custom-label:before,
.radio-custom + .radio-custom-label:before {
  background: $white;
  vertical-align: middle;
  display: inline-block;
  visibility: visible;
  text-align: center;
  margin-right: 10px;
}

.checkbox-custom + .checkbox-custom-label:before {
  content: '';
  border: 1px solid $light-green;
  width: 16px;
  height: 16px;
  padding: 2px;
  line-height: 16px;
  font-size: 16px;
  border-radius: 2px;
}

.radio-custom + .radio-custom-label:before {
  content: '\25EF';
  color: $grey-medium-light2;
  width: 22px;
  height: 22px;
  font-size: 22px;
  line-height: 22px;
}

.checkbox-custom-label,
.radio-custom-label,
.event-form .radio-custom-label,
.event-form .checkbox-custom-label {
  color: $grey-dark;
  cursor: pointer;
}

.checkbox-custom:checked + .checkbox-custom-label,
.radio-custom:checked + .radio-custom-label {
  color: $grey-dark;
}

.checkbox-custom:checked + .checkbox-custom-label:before {
  content: '\2713';
  color: $white;
  background: $light-green;
}

.radio-custom:checked + .radio-custom-label:before {
  content: '\25C9';
  color: $light-green;
}

.checkbox-custom:focus + .checkbox-custom-label,
.radio-custom:focus + .radio-custom-label {
  outline: none;
}

.radio-custom:disabled + .radio-custom-label:before {
  color: $grey-light;
}

.radio-custom:disabled + .radio-custom-label {
  cursor: default;
}

.checkbox-custom-label_text,
.radio-custom-label_text {
  display: block;
  padding-left: 2.5em;  
}

.checkbox-custom-label_text,
.radio-custom-label_text {
  margin-top: -1.55em;
}