@charset "UTF-8";

@import "variable";
@import "fonts";
@import "forms/form";
@import "component";
@import "modules/layout_responsive";
@import "modules/modal-window";
@import "modules/event-thumb2";
@import "modules/pod-thumb";
@import "modules/pod-announcement";
@import "modules/social-media";
@import "modules/what-is-this";
@import "modules/event/tags";
@import "modules/main-nav";
@import "modules/share-pod";
@import "modules/event/event_form";
@import "modules/event/event-flow";
@import "modules/header-bar";
@import "modules/cover-edit";
@import "modules/checkbox-radio-custom";
@import "modules/recaptcha";
@import "modules/page_skeleton";

.heading-primary {
  margin: 1em 0;
  text-align: center;
}

.content-main, main {
  background-color: $white;
  margin: 2em auto;
  max-width: $full_width;

  @media (max-width: 650px) {
    margin: 0 auto;
  }
}

// Pod Page 
.pod-header__main {
  height: 140px;
  margin-bottom: 5em;

  .pod-header_inner {
    position: relative;
    box-sizing: border-box;
    height: 120px;
    top: 20px;
    text-align: center;
  }

  @media (min-width: 500px) {
    height: 240px;
    .pod-header_inner {
      top: 120px;
    }
  }

  &.pod-bg-clear {
    @media (min-width: 500px) {
      height: 70px;
      .pod-header_inner {
        top: -50px;
      }
    }
  }
}

.pod-sub-info .pod-name {
  font-size: $font-M2;
  color: $text_color;
  padding: 0 1em;

  &.icon-before {
    @extend %ellipsis-text;
    &:before {
      font-size: .5em;
      padding-right: .5em;
    }
  }
}

.pod-header_inner {
  padding: 1.5em 1em;
}

.button__pod-header {
  position: absolute;
  bottom: 1em;
  right: 1em;
}


// Pod View
.pod-content-main {
  @extend %clearfix;
  padding: 2em 0;
  text-align: left;
  background-color: $bg_color;

  @media (max-width: $mobile_web_breakpoint) {
    border-top: 1px solid $grey-light;
  }

  @media (min-width: $mobile_web_breakpoint) {
    .block {
      width: 49%;
      float: left;
      &:first-child {
        margin-right: 2%;
      }
    }
  }
}

.pod-section {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 2em;

  &.full-column {
    min-height: 600px;

    header:not(:first-child) {
      margin-top: 2em;
    }
  }
  
  header {
    padding: .7em;
    font-size: $font-M;
    color: $grey-medium-light;

    > a {
      float: right;
    }
  }
}

.pod-section_main {
  padding: 1em;
  background-color: $white;
  @extend %clearfix;

  @media (max-width: $mobile_web_breakpoint ) {
    border-top: 1px solid $grey-light;
    border-bottom: 1px solid $grey-light;
  }

  .event-list__short {
    margin: -1em -1em 1em -1em;
  }

  .event-thumb {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $grey-light;
    margin: 0;
  }
}

.pod-section_zero-state {
  text-align: center;
  > p {
    color: $text_color;
    padding-bottom: 1em;
  }
}

.member-list-short {
  @extend %clearfix;
  padding: 2em 1em 1em 1em;
  text-align: center;
  background-color: $white;

  .avatar-small {
    display: inline-block;
    float: none;
    vertical-align: middle;
    border: 1px solid $grey-light2;
  }

  .member-list-short_all {
    position: relative;
    background-color: $link_color;
    color: $white;
    border-color: $link_color;
    
    > span {
      position: absolute;
      top: .1em;
      text-align: center;
      width: 32px;
      left: 0;
    }
  }
}

.button__pod-edit {
  @media (min-width: 500px) {
    display: none;
  }
}

.following {
  background-color: #fff;
  border-color: $link_color;
  color: $link_color;
  text-shadow: none;

  &:hover {
    background-color: #fff;
    border-color: $grey-medium-light;
    color: $grey-medium-light;
  }
}

.follow-status {
  position: absolute;
  right: 1em;
  bottom: -2.5em;
  color: $grey-medium-light;
  padding: .3em .5em;
  border: 1px solid $grey-medium-light;
  border-radius: 4px;
  line-height: 100%;
}

.pod-invite {
  position: absolute;
  left: 1em;
  bottom: -2.5em;
  .twitter-button:before {
    margin-right: .3em;
    vertical-align: middle;
  }
}

.pod-header_logo {
  position: relative;
  margin: 4em auto 0 auto;
  width: 82px;
}

.pod-logo_none {
  padding-top: .8em;
}

.pod-logo_add {
  cursor: pointer;

  .caption {
    padding: 1em 0 .3em 0;
    line-height: 130%;
    color: $grey-light;
  }

  .add-logo-img {
    width: 40px;
    height: 40px;
  }

  &:hover {
    .caption {
      color: $white;
    }
    background-color: rgba($black,.6);
  }
}

.pod-logo_edit {
  position: relative;
  margin: -88px auto 0 auto;
  width: 82px;
  height: 82px;
  cursor: pointer;
  border-radius: 2px;

  &:hover {
    background-color: rgba($black,.6);
  }

  .list-pop_content {
    position: absolute;
    left: 22px;
    top: 82px;
    width: 160px;
    text-align: left;
  }
}

.pod-logo_edit_button {
  display: block;
  width: 82px;
  height: 82px;
}

.edit-logo-img {
  width: 40px;
  padding: 45px 0 0 0;
}

.pod-host { 
  display: block;
  padding: .5em 1em 0 1em;
}

.pod-action-button {
  margin-top: 1em;
}

.pod-description {
  padding: 0 1em 2em 1em;
  color: $text-color; 
  > p {
    display: inline-block;    
    text-align: left;
  }
}

.toggle-description {
  display: block;
  text-align: left;
}

.pod-sub-info {
  text-align: center;
  color: $text-color;
  padding-bottom: 2em;
  position: relative;
}

.pod-events {
  padding: 2em 0;
  margin: 0 auto 2em auto;
  text-align: center;
  max-width: 600px;

  .heading-secondary {
    padding-bottom: 1em;
  }
}

.member-list {
  padding: 1em;
}

.pod__grey {
  background-color: $bg_color;
}

.pod-view-btm {
  text-align: center;
}

// Guide & Choose Pod
.pod-guide_visual {
  display: block;
  width: 16em;
  margin: 3em auto;
}

.pod-inner {
  text-align: center;
  padding: 1em;
  margin: 0 auto;

  &.inner {
    padding-top: 4em;
  }

  .heading-secondary {
    margin-bottom: 2em;
  }
}

.pod-blurb {
  text-align: left;
}

// Edit Create
.pod-create-form {
  margin: 2em auto 0;
  text-align: center;

  textarea[name="description"] { 
    min-height: 10em;
    line-height: 150%;
  }
}

.pod-create-form_inner {
  padding: 0 1em;
  text-align: left;
}

.pod-set-privacy {
  display: none; // Hide until implemented
}

.pod-set-privacy_inner {
  padding: 1em;
  text-align: left;

  .svg-lock {
    fill: $grey-light;
    margin: -.2em .5em 0 0;
    float: left;
  }
}

.is-private {
  .svg-lock {
    fill: $grey-medium;
  }
}

.pod-plan-list {
  > li {
    padding-bottom: .5em;
    margin-bottom: .5em;
    border-bottom: 1px solid $grey-light2;
  }
  margin-bottom: .5em;
}

.pod-plan_price {
  float: right;
  color: $grey-medium;
  font-size: $font_S;
}

.pod-plan_description {
  clear: both;
  color: $grey-medium-light;
}

.pod-create-button {
	margin-top: 1em;
}

.recaptcha_branding {
  text-align: center;
  padding: 4em 1em 1em 1em;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .event-flow-content {
    margin: inherit;
    text-align: center;
  }
}

.global-footer {
  padding-bottom: 42px;

  @media only screen and (max-device-width: 900px) {
    padding: .7em .7em 5em .7em!important;
  }
}

.fb-comments span, 
.fb-comments iframe span[style],
.fb-comments, .fb-comments iframe[style] {
  width: 100% !important;
}