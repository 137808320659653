.header-inner {
  max-width:inherit !important;
  
  .icon-arrow-left {
    color: #FFF;
    display: inline-block;
    vertical-align: top;
  }
}
.header-text {
  overflow:hidden;
  text-overflow:ellipsis;
  display:-webkit-inline-box;
  -webkit-line-clamp:2;
  -webkit-box-orient:vertical;
  max-height: 2.4em !important;
  width: 80%;
}
