@charset "UTF-8";

.what-is-this {
	display: inline-block;
	width: 25px;
	height: 25px;
	margin-left: 6px;
	padding: 0 8px;
	line-height: 25px;
	color: lighten($grey-medium, 30%);
	background-color: $bg-color;
	font-family: "Arial Rounded", $sans-serif;
	border: 1px solid lighten($grey-medium, 40%);
	border-radius: 50%;
	cursor: pointer;
	text-align: center;
	box-sizing: border-box;
	&:hover {
	  background-color: darken($bg-color, 3%);
	}
}

.what-is-this__small {
    font-style: normal;
    font-size: .6em;
    width: 1.5em;
    padding: 0;
    height: 1.5em;
    line-height: 150%;
    vertical-align: top;

    &:hover {
      border-color: $grey-medium;
    }
}

.what-mobile {
	width: 26px;
	height: 26px;
	font-size: $font-M;
	padding: 1px 7px;
}

