@import "fonts";

// Pod thumb
.pod-thumb {
  @extend %clearfix;
  box-sizing: border-box;
  @include vertical-gradient(rgba($black, .6), rgba($black, 0));
  position: relative;
  border-radius: 4px;
  padding: 1.5em;
  text-align: left;
  height: 184px;
  max-width: 500px;
  margin-bottom: 1em;

  .pod-name {
    margin-top: 0;
    padding-right: 5em;
    padding-bottom: .5em;
    max-height: 3em;
    overflow: hidden;
  }

  .svg-join {
    vertical-align: middle;
  }
}

.pod-wrap {
  margin-bottom: 2em;
}

.pod-thumb-wrap-narrow {
  width: 350px;
  display: inline-block;
  vertical-align: top;
  margin: 0 .5em;

  .pod-thumb {
    height: 130px;
    padding: 1em;
  }

  .pod-thumb_logo {
    right: 1em;
  }

  @media (max-width: 350px) {
    width: 300px;
  }
}

.pod-thumb_link {
  display: block;
  max-width: 500px;
  border-radius: 4px;
  margin: 0 auto;
}

.pod-thumb__with-btm,
.pod-thumb__with-btm .pod-thumb {
  margin-bottom: 0;
  border-radius: 4px 4px 0 0;
}

a.pod-thumb_link {
  &:hover .pod-thumb {
    background-color: rgba($black, .6);
  }
}

// BG Colors
.pod-bg1 { background-color: #7FD2DB; }
.pod-bg2 { background-color: #CC5A65; }
.pod-bg3 { background-color: #E6B147; }
.pod-bg4 { background-color: #BCCC5A; }
.pod-bg5 { background-color: #5A72CC; }

.pod-thumb_logo {
  position: absolute;
  right: 1.5em;
  top: 0;

  .pod-logo {
    border-radius: 0 0 2px 2px;
  }
}

// Logo
.pod-logo {
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 2px 2px;
  border: 1px solid rgba($black, .4);
  background-color: $white;
  color: $grey-medium-light;
  font-size: $font-S;
  line-height: 150%;
  text-align: center;
}

.pod-thumb_sub {
  color: $white;
}

// Pod name
.pod-name {
  @extend %font-ubuntu;
  font-size: $font-M1;
  line-height: 1.2em;
  color: $white;
  font-weight: normal;
}

.pod-member-count {
  color: $white;
  margin-right: .5em;
}

// Events under pod thumb
.pod-event-list {
  text-align: left;
  max-width: 500px;
  margin: 0 auto 1em auto;
  padding-left: 0;

  > li {
    position: relative;
    background-color: $white;
    padding: 1em 1em 1em 3em;
    border-top: 1px solid $grey-light;

    > a {
      display: block;
    }
  }

  time, .event-location, .events-zerostate-message {
    color: $grey-medium-light;
    font-size: $font-S;
    }
    .events-zerostate-message {
    padding: 1.2em 1em 1em 4em;
  }

  .svg-calendar {
    position: absolute;
    top: 1.2em;
    left: 1em;
    fill: $grey-medium;
  }

  .svg-lock-wrap {
    position: absolute;
    top: 1.5em;
    right: 1em;
    fill: $grey-light;
  }

  .event-hosting {
    @extend %clearfix;
  }

  .db-status {
    float: right;
    padding-top: 1em;
    font-size: $font-S;
    color: $grey-medium;
    font-weight: normal;
    background: no-repeat 0 center;
  }

  .db-pending_approval,
  .db-on_hold,
  .db-canceled,
  .db-live,
  .db-finished,
  .db-finished,
  .db-closed,
  .db-sold_out {
    color: $red;
  }
}

li.pod-event-list_short {
    padding: 0;
    > a {
        display: inline-block;
        padding: 1em 1em .9em 3em;
    width: 85%;
    }
    .with_more-events {
    width: 59%;
    }
}
.pod-event-list_short {
  height: 4.5em;
  border: 1px solid $grey-light;
  border-radius: 0 0 4px 4px;

  .pod-event-name {
    @extend %ellipsis-text;
        margin: 0;
  }
  .more-events {
    float: right;
    border-left: 1px solid $grey-light;
    width: 21%;
    min-width: 55px;
    text-align: center;
    box-sizing: border-box;
        padding: 1em 1em .9em 1em;
        font-size: smaller;

    svg {
      display: block;
      margin: 0 auto;
      fill: $grey-medium;
    }

    &:hover {
      color: $link_color;
      svg {
        fill: $link_color;
      }
    }
  }
}

.event-thumb-btm__extend {
  @extend %border-box;
  padding: 0 10px;
  line-height: 150%;
  text-align: left;

  @media (min-width: 500px) {
    top: 0;
    left: 50%;
    width: 50%;
    height: 182px;
    margin: 0;
    padding: 5px 15px;
    background-color: $white;
    border-radius: 0 5px 5px 0;
    border-left: none;

    &.list-pop {
      position: absolute;
    }
  }

  .btn-additional-data {
    left: auto;
    right: 0;
    width: 100%;
    @media (min-width: 500px) {
      width: 200%;
      left: auto;
      right: 0;
    }
  }
}

.pod-event-name {
  @extend %font-ubuntu;
  font-size: $font-M;
  font-weight: normal;
  color: $link_color;
  display: block;

  &:hover {
    color: $grey-medium;
  }
}

#member-pods {
  .button__cancel-subscription {
        position: relative;
    float: right;
    margin-top: -2em;
    margin-right: 0;
  }
}

#admin-pods {
  .button__cancel-subscription {
        position: relative;
    float: right;
    margin-top: -2em;
    margin-right: 0;
  }
}