@charset "UTF-8";

@import "variable";
@import "component";
@import "grid";


// New TODO: include this partial file instead of reading in modal-window.css externally.
// IMPORTANT: this stylesheet has a larger closing button and uses border-box on the popup

// Dialog Overlay
#fade {
  cursor: pointer; // don't remove - required for mobile safari to handle click event
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
  overflow-y: scroll;
  background-color: rgba( $black, 0.7 );
  -webkit-overflow-scrolling: touch;
}

// Dialog Window
.popup_block {
  @extend %border-box;
  position: relative;
  display: none;
  min-width: 300px;
  max-width: 95%;
  background-color: rgba( $white, 1.0 );
  padding: 35px;
  margin: 80px auto;
  z-index: 999999;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.9);
  @extend %clearfix;
  border-radius: 2px;

  .window-iframe {
    max-width: 95%;
  }

  .close {
    outline: none;
    background: transparent;
    border: 1px solid transparent;
    display: none;
    position: absolute;
    right: 15px;
    top: 15px;
    @include icon-cross;
    @include icon-only(25px);
    color: $grey-medium-light;
    z-index: 999;

    color: rgba($black, .3);

    &:hover {
      color: rgba($black, .5);
      cursor: pointer;
    }

    @media only screen and (max-width: 640px) {     
        @include icon-only(18px);
    }
  } 


  header {
    background: transparent;
    text-align: left;

    h1 {
      color: rgb(0, 0, 0);
      font-family: $ubuntu;
    }
  }

  .header__center {
    text-align: center;
    padding: 20px 0;

    h2 {
      padding-bottom: 10px;
      font-size: $font-L;
      font-weight: normal;
      font-family: $ubuntu;
    }

    .sub-text {
      font-size: $font-M1;
    }
  }

  .content {
    margin: $indent 0 0;
    text-align: left;
  }

  .content__center {
    text-align: center;
    padding-bottom: 20px;
  }
}

.popup_block__flex {
  position: fixed;
  min-width: 300px;
  margin-top: 0;
  top: 2.5%;

  @include MQ(XS)  {
    left: 2.5%;
    right: 2.5%;
    width: 95%;
  }

  @include MQ(S)  {
    left: 2.5%;
    right: 2.5%;
    width: 95%;
  }

  @include MQ(M)  {
    left: 10%;
    right: 10%;
    width: 80%;
  }

  @include MQ(L)  {
    left: 20%;
    right: 20%;
    width: 60%;
  }

  @include MQ(XL)  {
    left: 30%;
    right: 30%;
    width: 40%;
  }
}
