@import "variable";

.recaptcha_wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
  
.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha_branding {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: $grey-medium;
}