.boost-survey-popup {
  color: $grey-light3;

  p {
      text-align: center;
  }
}

.survey-options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  margin-top: 2em;
  padding-top: 2em;
  border-top: 1px solid $grey-light;

  > li {
    margin: 0.5em;
  }
}
