@import "functions";
@import "variable";
@import "grid";

#form-editor {
  min-width: 600px;
}

.form-new-section {
  text-align: right;
  width: 520px;
  }

.form-templates {
  width: 520px;
  margin: 10px auto 30px auto;
}

.dl-btn span {
  background: url('images/icon-dl-wh.png') no-repeat 0 50%;
  padding-left: 40px;
  display: inline-block;
}
#form-result-section h2 {
  font-size: $font-M2;
  line-height: 150%;
  font-weight: normal;
}
#form-result-section h2 .small {
  font-size: 50%;
  padding: 10px 0 0 0;
}
#form-result-section .box-grey {
  width: 500px;
  margin: 20px auto 0 auto;
}
.form-template-btn-content {
  float: left;
  width: 410px;
  border-right: 1px solid #999;
  border-radius: 4px 0 0 4px;
  height: 65px;
  padding: 8px 15px;
}
.form-templates .form-template-select,
.form-templates .form-template-select-legacy {
  display: block;
  float: left;
  text-align: center;
  padding: 8px 10px 8px 10px;
  width: 105px;
  border-left: 1px solid #FAF9F6;
  border-radius: 0 4px 4px 0;
  height: 65px;
  font-weight: bold;
  line-height: 250%;
  font-size: 110%;
  position: relative;
  min-width: 0;
}
// overwrite so it doesn't behave like a button
.form-templates .button.nobutton:hover {
  background-color: #f5f5f7;
  box-shadow: 0 1px rgba(0, 0, 0, 0.08), 0 0 1px rgba(255, 255, 255, 0.2) inset;
  color: rgba(0, 0, 0, 0.7);
  border-right: 1px solid #999;
  text-decoration: none;
}

.form-templates .form-template-select a {
  display: block;
}
.form-templates .form-template-select:hover,
.form-templates .form-template-select.selected,
.form-templates .form-template-select.stopped {
  background: #333333 url('images/form-check.png') no-repeat 50% 50%;
  -webkit-box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
  border: 1px solid #333;
  color: #FFF;
  text-indent: -99999px;
}
.form-templates .form-template-select:hover {
  opacity: 0.7;
}
.form-templates .form-template-select.stopped,
.form-templates .form-template-select.selected:hover {
  background-image: url('images/icon-stop-form.png');
}
.form-templates .form-template-select.stopped:hover {
  background: #333333 url('images/form-check.png') no-repeat 50% 50%;
}

.form-template-btn-content.button {
  display: block;
  margin: 0;
  text-align: left;
  position: relative;
  text-shadow: none;
}
.form-template-btn {
  padding-bottom: 5px;
}
.form-templates .form-template-btn-content {
  padding: 3px 15px 13px;
}
.form-template-btn-content h3 {
  line-height: 180%;
  padding: 0 0;
  height: 30px;
  overflow: hidden;
}
.form-template-btn-content > span {
  padding-left: 15px;
  display: inline-block;
  margin-right: 10px;
}
.form-template-btn-content > span a {
  color: #000;
  opacity: 0.7;
  font-weight: normal;
}
.form-template-btn-content > span a:hover {
  text-decoration: underline;
  opacity: 1;
}
.form-template-btn .view-form {
  padding-left: 4px;
}
.form-template-btn .edit-form {
  background: url('images/icon-edit.png') no-repeat 0 50%;
  padding-left: 15px;
}
.form-template-btn .delete {
  background: url('images/icon-delete-bk.png') no-repeat 0 50%;
}
.form-template-btn .copy {
  background: url('images/icon-copy.png') no-repeat 0 50%;
}
.form-template-btn .history {
  background: url('images/icon-history.png') no-repeat 0 50%;
}

.form li {
  position: relative;
}
.label,
legend {
  display: block;
  font-weight: bold;
}
.form .field-section li span {
  float: left;
}
.form .field-section li span label,
.form li div label {
  clear: both;
  display: block;
  text-align: left;
}
.form .field-section li .choice {
  display: inline;
  font-size: 90%;
  color: #000;
}
.form li div.left,
.form li div.right {
  width: 48%;
}
.form li div.left .txt-field,
.form li div.right .txt-field {
  width: 97%;
}
.form li .required {
  font-style: normal;
  font-size: 14px;
}
.req-indicator {
  padding-right: 4px;
  float: left;
}
#edit-form {
  position: relative;
}
#field-form-name {
  padding: 0 20px 10px 20px;
  background-color: #EEE;
}
.form-creator {
  text-align: left;
  margin: 10px auto 0px auto;
  border: 10px solid #EEE;
}
.form-creator li {
  padding: 10px 0 0 0;
}
.form-creator-name {
  text-align: center;
  position: relative;
}
.form-creator-name .txt-field {
  width: 300px;
}
.btn-form-preview {
  position: absolute;
  right: 150px;
  top: 15px;
}
.form-creator .field-edit-link {
  display: none;
  position: absolute;
  right: 20px;
  top: 0px;
}
.form-creator .action-delete {
  display: none;
  position: absolute;
  background-image: url('images/icon-del-bk.png');
  width: 20px;
  height: 20px;
  right: -4px;
  top: 8px;
}
.form-creator .custom-form-label {
  padding: 0 20px;
}

.form-creator .custom-form-field {
  padding: 0 20px 10px 20px;
}
.form-creator .field-section li:hover,
.form-creator .field-section li.selected {
  background: #FFF7A6;
  border-color: #FFFF66;
}
.form-creator .field-section li:hover .field-edit-link,
.form-creator .field-section li:hover .action-delete {
  display: block;
}
.form-creator .field-section li:hover,
.form-creator .field-section li:hover input,
.form-creator .field-section li:hover label,
.form-creator .field-section li:hover textarea {
  cursor: pointer;
}
.form-creator .field-edit {
  position: relative;
  padding: 10px 20px;
  margin-top: 10px;
  background: $bg_color;
  border-bottom: 1px solid #EEE;
  -webkit-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.07);
  box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.07);
}
.form-creator .field-section .field-edit .arrow-down {
  display: block;
  position: absolute;
  top: -10px;
  left: 18px;
  width: 17px;
  height: 10px;
  background: url('images/arrow-inset.png') no-repeat 0 0;
}
.form-creator .field-section .field-edit:hover {
  background: $bg_color;
  border-bottom: 1px solid #EEE;
}
.form-creator .field-edit .txt-field {
  width: 250px;
}
.form-creator .field-edit div label {
  padding-bottom: 0;
}
.form-creator .field-section .field-edit span,
.form-creator .field-section .field-edit span label {
  float: none;
  display: inline;
}
.form-creator .field-section .field-edit span label {
  padding-right: 10px;
}
.form-creator .edit-display {
  background: url('images/icon-edit.png') no-repeat 0 50%;
  padding-left: 15px;
}
.form-creator .field-edit .edit-label .txt-field {
  width: 265px;
  margin-bottom: 10px;
}
.form-creator .field-edit .edit-label .txt-field-wide {
  width: 513px;
}
.form-creator .edit-label {
  padding-bottom: 10px;
}
.form-creator .field-section .edit-options span {
  display: block;
  padding-bottom: 4px;
}
.form-creator .delete-choice {
  width: 20px;
  height: 20px;
  background-image: url('images/icon-del-bk.png');
  display: inline-block;
  cursor: pointer;
}
.form-creator .edit-options li {
  padding: 0 0 10px 0;
  list-style: decimal;
  margin-left: 20px;
}
.form-creator .edit-options li:hover {
  background: none;
}
.form-field-display {
  position: relative;
  padding: 5px 0;
}
.form-field-display .sortable-indicator {
  display: none;
  position: absolute;
  top: 5px;
  left: -40px;
  background: url('images/icon-sortable.png') no-repeat 0 0;
  width: 16px;
  height: 40px;
}
.form-field-display:hover .sortable-indicator {
  display: block;
}
.form-history-baloon {
  display: none;
  background-color: #ffffff;
  padding: 20px 30px;
  z-index: 999999;
  -webkit-box-shadow: 0px 0px 20px #000;
  -moz-box-shadow: 0px 0px 20px #000;
  box-shadow: 0px 0px 20px #000;
  border-radius: 4px 0 0 4px;
  position: absolute;
  top: 0;
  left: 0;
}
.btn-save-form {
  margin: 0 auto;
}
.custom-form-bottom {
  position: relative;
  padding: 10px 10px 20px 10px;
  background: #EEE;
  margin: 0 auto 20px auto;
}
.add-field-section {
  display: inline;
}
.add-field-section:hover .select-add-field {
  display: block;
}
.btn-add-field span {
  background: url('images/icon-add.gif') no-repeat 0 50%;
  padding-left: 20px;
}
.select-add-field {
  width: 360px;
  margin: 0 auto 0 auto;
  padding-top: 10px;
  background: url('images/icon-arrow-bk-up.png') no-repeat 50% 0;
  position: absolute;
  top: 30px;
  left: 100px;
  z-index: 10;
  display: none;
}
.select-add-field ul {
  padding: 20px;
  background: #333;
  -moz-box-shadow: 4px;
  -webkit-box-shadow: 4px;
  box-shadow: 4px;
  border-radius: 4px;
}
.select-add-field li {
  width: 50%;
  float: left;
  padding-bottom: 5px;
}
.select-add-field li .btn-sub {
  display: block;
  text-align: center;
}
.include-privacy-policy {
  text-align: center;
  font-size: 90%;
  color: #666;
  padding: 0 20px;
}
.include-privacy-policy p {
  padding-bottom: 10px;
}
.include-privacy-policy label {
  font-size: 110%;
  font-weight: bold;
}
.edit-privacy-policy textarea {
  font-size: 90%;
  color: #333;
  height: 100px;
}
.form-flow-preview {
  text-align: left;
  padding: 20px 0 20px 20px;
  margin-top: 40px;
}
.form-flow-preview ul {
  background: url('images/line-dotted.gif') repeat-x 0 50%;
  margin: 10px 20px 0 20px;
}
.form-flow-preview ul li {
  font-size: 85%;
  display: block;
  width: 70px;
  height: 40px;
  float: left;
  padding: 10px;
  background: #ffffff url('images/icon-close-mini.gif') no-repeat 105px 5px;
  border: 3px solid #666;
  margin: 5px;
  border-radius: 6px;
  font-weight: bold;
}
.form-flow-preview ul li.selected {
  color: #FF6600;
  border-color: #FF6600;
}
.form-flow-preview ul li.form-flow-login {
  background: #666;
  font-size: 10px;
  width: 50px;
  height: 20px;
  padding: 2px 3px;
  margin: 35px 4px 0 4px;
  border: none;
  color: #FFF;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-history {
   h2 {
    text-align: left;
    background: url('images/icon-history-s.png') no-repeat 0 5px;
    padding-left: 25px;
    font-weight: normal;
  }

  .close-btn {
    margin: 5px auto 0 auto;
    display: block;
  }
}

.form-history-list li {
      border-bottom: 1px solid #CCC;
      padding-top: 5px;
      padding-bottom: 5px;
}

.fs_form_container {
  .text[disabled],
  .txt-field[disabled] {
    border: 1px solid #adadad;
    border-top-color: #a3a3a2;
    background-color: rgb(255, 255, 255) ! important;
  }
}

// Text Field
.text,
.txt-field {
  width: 100%;

  &.min {
    text-align: center;
  }

  @media (min-width: 768px) {
    width: $colspan05;
  }
}

textarea.text {
  height: 100px;
}

// Subform
.field.fs_subform_container {
  margin-bottom: 10px;
}

.sub-form {
  display: block;
  width: auto;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    display: inline-block;
    width: (($colspan05 - 5px) / 2);
  }

  .txt-field {
    width: 100%;
  }
}

// Date Field
.date-field {
  .date {
    width: 3em;
    margin-right: 5px;
    text-align: center;
  }

  .year {
    width: 3.5em;
    text-align: center;
  }

  img {
    margin-left: 5px;
    vertical-align: middle;
  }
}

/// Date Picker Icon
.form-helper-calendar {
  cursor: pointer;
}

// Address Subform
.address {
  .sub-form {
    margin-bottom: 10px;
  }
}
