.co-manage-popup {
  color: $grey-light3;

}

.co-manage-steps {
  margin-top: 2em;
  padding-top: 2em;
  border-top: 1px solid $grey-light;

  > li {
    padding: 0 0 2em 3.5em;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 1.5em;
    position: relative;

    &:after {
      content: "";
      width: 1.6em;
      height: 2em;
      background-color: $white;
      opacity: 0.5;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 1;   
    }
  }

  .pod-url {
    display: inline-block;
    margin-top: 1em;
    padding: 1em 1.5em;
    border: 1px solid $grey-light;
    border-radius: 4px;
  }
}

.co-manage-steps_pod {
  background-image: url('images/pod.svg');
}

.co-manage-steps_add {
  background-image: url('images/add-co-manager.svg');
}