
// Tags
.tag-list {
  padding: .5em 0 0 0;

  > li {
    display: inline-block;
  }

  .tag {
    display: inline-block;
    margin-right: .5em;
    margin-bottom: .8em;
    border: 1px solid $link_color;
    border-radius: 2em;
    padding: .3em 1em;
    font-family: $ubuntu;
    font-size: $font-S;
    &:hover {
      background-color: $link_color;
      color: $white;
    }

    &.large {
      font-size: $font-M;
    }
  }

  .button {
    margin-bottom: .5em;
    margin-right: .5em;
  }

  &.no-discover {
    padding-top: 0;
    min-height: 1px;

    .tag {
      border-color: $grey-medium;
      color: $grey-medium;
      &:hover {
        background-color: transparent;
        color: $grey-medium;
      }
    }
  }
}