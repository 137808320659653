
// Events
.event-list {
	box-sizing: border-box;

	> li {
		margin-top: 1em;	
	}
}

.event-list__short {
	.event-thumb_info {
		float: none;
		width: 100%;
	}

	.event-thumb_cover {
		display: block;
		margin-top: .5em;
		width: 100%;
	}
	.event-location {
		display: block;
	}
}

.event-thumb {
	@extend %clearfix;
	position: relative;
	border: 1px solid $grey-light;
	border-radius: 2px;
	background-color: $white;
	
	text-align: left;

	.featured-label {
		position: absolute;
		top: 0;
		right: 0;
	}
}

.event-thumb_link {
	@extend %clearfix;
	display: block;
	padding: 1em;

	&:hover {
		background-color: rgba($grey-light2, .2);
		.event-thumb_name {
			color: $link_color;
		}
	}
}

.event-thumb_detail {
	box-sizing: border-box;
	padding-left: 4em;
	line-height: 130%;

	.datetime {
		font-size: $font-S;
		display: block;
		font-weight: bold;
		color: $grey-medium;
	}
}

.event-thumb_location,
.event-thumb_organizer {
	font-size: $font-S;
	color: $grey-medium-light;
}

.event-thumb_location {
	@extend %ellipsis-text;
	display: block;
}

.event-thumb_info {
	@media (max-width: 560px) {
		float: none;
		width: 100%;
	}

	// Ellipsis on event name
	.ellipsis {
	    overflow: hidden;
	    height: 45px;
	}

	.ellipsis:before {
	    content: "";
	    float: left;
	    width: 5px;
	    height: 45px;
	}

	.ellipsis > *:first-child {
	    float: right;
	    width: 100%;
	    margin-left: -5px;
	}

	.ellipsis:after {
	    content: "\02026";
	    box-sizing: content-box;
	    float: right;
	    position: relative;
	    top: -25px;
	    left: 100%;
	    width: 3em;
	    margin-left: -3em;
	    padding-right: 5px;
	    text-align: right;
	    background: linear-gradient(to right,rgba(255,255,255,0),white 50%,white);
	}
}

.event-cover-wrap {
  width: 100%;
  height: 0;
  padding-top: 450px / 920px * 100%;
  position: relative;
  margin-top: 5px;
}

.event-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url('images/event-no-cover.jpg');
}

.event-thumb_cover {
	float: right;
	width: 24%;
	height: 80px;
	background-size: cover;
	background-position: 50%;

	@media (max-width: 560px) {
		margin-top: .5em;
		width: 100%;
	}
}

.event-thumb_name {
	color: $grey-dark;
	font-size: $font-default;
	font-weight: normal;
	line-height: 150%;
}

.event-cal {
	float: left;
	padding-right: 1em;
	margin-right: .5em;
	border-right: 1px solid $grey-light;
	width: 2em;
	text-align: center;
	line-height: 130%;
	font-family: $ubuntu;
	font-weight: bold;
	color: $grey-medium;

	.month {
		display: block;
		font-size: $font-S;
		text-transform: uppercase;
	}
	.day {
		display: block;
		font-size: $font-M1;
	}
}

// Adjustments 

.top-cover { // as on featured events in top page
	padding: 0 0 1em 0;

	.event-cover-wrap {
		margin: 0 0 1em 0;
	}

	.event-cal {
		padding: 0 .8em .8em;
	}

	.event-thumb_detail {
		margin-right: .8em;
		padding-left: 4.6em;
	}
}