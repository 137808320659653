.event-flow-wrap {
  position: fixed;
  width: 100%;
  bottom: 0;
  margin: 0  auto;
  z-index: 9;
}

.event-flow {
  min-height: 60px;
  max-width: 100%;
  background-color: rgba($link_color, 0.95);
  margin: 0 auto;
  padding: 20px;
  color: $white;
  font-size: $font-M;
  font-weight: normal;
  box-sizing: border-box;

  .event-flow-content {
    max-width: 960px;
    display: none;
  }
  a {
    color: #fff;
    text-decoration: underline;
  }
}

.event-flow-content {
  position: relative;
  margin: 0 auto;
    @media only screen and (max-width: 640px){
      margin: 0 10px 0 55px;
    }
    &:before {
      content:'';
      width: 30px;
      height: 30px;
      margin: -5px 20px 0 20px;
      left:0;
      position:absolute;
      display: block;
      color: $white;
      background-size: 30px;
        @media only screen and (max-width: 640px){
          width: 40px;
          height: 40px;
          background-size: 40px;
          margin: 2px 10px 0 -55px;
        }
    }
}

.event-flow-content-created {
  max-width: 700px!important;
  &:before{
    background-image: url('images/event-creation-check.svg');
  }
}

.event-flow-content-fill-setting {
  max-width: 700px!important;
  &:before{
    background-image: url('images/event-creation-gear.svg');
  }
}

.event-flow-content-fill-required {
  max-width: 700px!important;
  &:before{
    background-image: url('images/event-creation-gear.svg');
  }
}

.event-flow-content-fill-required-and-setting {
  max-width: 840px!important;
  &:before{
    background-image: url('images/event-creation-check.svg');
  }
}

.event-flow-content-ready {
  max-width: 510px!important;
  height: 5em;
  left: 30px;
  text-align: center;
    @media only screen and (max-width: 640px){
      font-size: $font-M;
      letter-spacing: -.04em;
      margin: 0 .5em 0 .5em;
      padding-right: 8px;
      }
  &:before{
    background-image: url('images/event-creation-smiles.svg');
      @media only screen and (max-width: 640px){
        margin: -4px 8px 0 -44px;
      }
  }
}

#event-flow-publish-button {
  position: absolute;
  margin-top: 1em;
  margin-left: -6.5em;
  padding: .3em 2.75em .3em 3.25em;
  color: $text_color;
  background-color: $white;
  border-radius: 4px;
  font-size: $font-M;
  text-decoration: none;
  letter-spacing: .05em;

  &.btn-loading {
    font-size: $font-S;
    padding: 5px 3px;
  }

  &:after {
    margin-left: 4px;
    font-size: 14px;
  }

  @media only screen and (max-width: 667px){
    margin-top: .5em;
    margin-left: -9em;
    padding: .2em 5em;
  }
}

// Legal footer links need to be visible
footer.js-event-flow-footer {
  padding-bottom: 250px;
  @media only screen and (max-width: 670px){
    padding-bottom: 110em;
  }
}
