@mixin social-media-button($color) {
  color: #fff;
  text-shadow: none;
  font-weight: normal;
  background-color: $color;
  border-color: darken($color, 5%);
  box-shadow: none;

  &:hover {
    background-color: darken($color, 5%);
    border-color: darken($color, 5%);
  }

  &.button.icon {
    position: relative;
    padding-left: 40px;
    text-align: left;

    &.icon-left {
      &:before {
        position: absolute;
        top: 50%;
        left: 10px;
        width: 25px;
        height: 25px;
        margin-top: -14px;
        font-size: 25px;
      }
    }

    &.icon-right {
      &:after {
        position: absolute;
        top: 50%;
        right: 10px;
        width: 25px;
        height: 25px;
        margin-top: -14px;
        font-size: 25px;
      }
    }

  }

  &.button.icon-before {
    &:before {
      position: absolute;
      top: 50%;
      left: 10px;
      width: 25px;
      height: 25px;
      margin-top: -12px;
      margin-right: 20px;
      font-size: 25px;
    }
  }

  @if $color == $white {
    border-color: $grey-medium;
    &:hover {
      background-color: $white;
      border-color: $black;
      color: $black;
    }
  }
}

.facebook-button.button {
  @include social-media-button(#45619d);
    &:before{
      left:8px!important;
  }
  &:hover {
    color: $white;
  }
}

.twitter-button.button {
  @include social-media-button(#00AcEd);
  &.button.icon-before {
    &:before {
      width: 22px;
      height: 22px;
      font-size: 22px;
    }
  }
  .icon-twitter:before {
    color: $white;
  }
  &:hover {
    color: $white;
  }
}

.peatix-login-button.button {
  @include social-media-button($grey-light2);
  color: $grey-medium;
  &:before {
    color: $link-color;
  }
}

.google-button.button {
  @include social-media-button($white);
  color: $grey-medium;
  padding-left: 40px !important; 
}

.google-button.button, 
.google-button.button:hover {
  background-image: url('images/google-logo.svg');
  background-position: 10px 50%;
  background-repeat: no-repeat;
}

.apple-button.button {
  @include social-media-button($white);
  background-image: url('images/apple-logo.svg');
  background-repeat: no-repeat;
  background-size: 23px;
  color: $grey-medium;
  background-position: .6em 50%;
  padding: .8em .5em .8em 40px !important; 
}