// Share upon join modal

.share-modal {
       max-width: 300px;
       margin: 2em auto 1em auto;
       text-align: center;
       .button {
               display: block;
               margin-top:.5em;
               padding-top: .7em;
               padding-bottom: .7em;
       }
}

