@import "variable";

.announcement {
    color: $text_color;

    >.announcement-list,
    >.announcement-form,
    >.announcement__message {
        display: none;
    }

    &.announcement--show-list .announcement-list,
    &.announcement--show-form .announcement-form,
    &.announcement--show-no-message .announcement__message {
        display: block;
    }

    .announcement__message {
        width: 100%;
        text-align: center;
    }
}

.announcement-list {
    text-align: left;

    .announcement-list__item {
        .date,
        .announcement {
            display: inline;
        }

        .date {
            font-weight: bold;

            &:after {            
                content: ' - ';
                font-weight: 100;
            }
        }

        .announcement {
            .read-more:after {
                color: $link_color;
                cursor: pointer;
            }

            .read-more-target {
                display: none;
            }

            &.show-full {
                .read-more:after {
                    display: none;
                }

                .read-more-target {
                    display: inline;

                    &:after {
                        color: $link_color;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.announcement-form {
    text-align: right;

    .announcement-form__textarea {
        width: 100%;
        min-height: 100px;
        padding: 5px 10px;
        border: 1px solid $grey-light2;
        font-family: "Helvetica Neue Light", "HelveticaNeue-Light", arial;
        color: $text_color;

        &:focus {
            background: transparent;
        }
    }

    .announcement-form__submit {
        display: inline;
        margin: 10px 0 0 0;
    }

    .announcement-form__message {
        font-size: $font-S;
        color: $grey-light;
    }
}
